import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import './header.css'

const Header = ({ siteTitle }: { siteTitle: string }) => (
  <header>
    <nav>
      <ul style={{ margin: 0 }}>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/code">Code</Link>
        </li>
        <li>
          <Link to="/dirt">Dirt</Link>
        </li>
        <li>
          <Link to="/renaissance">Renaissance</Link>
        </li>
        <li>
          <Link to="/cv">About</Link>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
