import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Footer = ({
  siteTitle,
  siteDescription,
}: {
  siteTitle: string
  siteDescription: string
}) => (
  <footer
    style={{
      position: "fixed",
      bottom: 0,
      right: 0,
      width: `225px`,
      background: `linear-gradient(125deg, rgba(255,0,0,0) 35%, #ffffff 100%)`,
      paddingBottom: `25px`,
      paddingRight: `2rem`,
      textAlign: "right",
      //   boxShadow: `1px -3px 9px 0px rgba(0,0,0,0.5)`,
    }}
  >
    <StaticImage
      className="bio-avatar"
      formats={["AUTO", "WEBP", "AVIF"]}
      src="../images/sean-mayhew-icon.png"
      width={65}
      height={65}
      quality={95}
      alt="Sean Mayhew with my Kids"
    />
    <h1 style={{ margin: 0, marginTop: 15, padding: 0, textAlign: `right` }}>
      <Link
        to="/"
        style={{
          color: `#141526`,
          display: "block",
          textDecoration: `none`,
          fontSize: `1rem`,
          margin: 0,
          padding: 0,
          textTransform: `uppercase`,
        }}
      >
        {siteTitle}
      </Link>
    </h1>
    <h2
      style={{
        color: `rgb(205, 23, 25)`,
        fontWeight: "normal",
        fontSize: `.9rem`,
        margin: 0,
      }}
    >
      {siteDescription}
    </h2>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
  siteDescription: ``,
}

export default Footer
